import { RequestService } from '~/services/RequestService'
import CareerRoutesCardModel from '~/model/Cards/CareerRoutesCardModel'

class CareerRoutesAccountService {
  readonly url: string = '/api/v1/yavagro/career-routes/list/my'
  readonly urlSetProfessions: string = '/api/v1/yavagro/career-routes/set-selected'

  constructor(private requestService: RequestService) {}

  public async getAccountProfessions<T>(): Promise<Array<CareerRoutesCardModel>> {
    const res: any = await this.requestService.request<T>({
      url: this.url
    })
    return res.data.data
  }

  public async setSelectedProfessions(ids: number[]): Promise<number> {
    const { status } = await this.requestService.request({
      data: {
        career_routes_ids: ids
      },
      method: 'POST',
      url: this.urlSetProfessions
    })
    return status
  }
}

export default CareerRoutesAccountService
