import type { UnknownObject } from '~/shared/interfaces'

export function isUnknownObject(data: unknown): data is UnknownObject {
  return typeof data === 'object' && data !== null && !Array.isArray(data)
}

export function isPrimitive(data: unknown): data is number | string | boolean | null {
  const type = typeof data
  return type === 'number' || type === 'string' || type === 'boolean' || data === null
}

export function isString(data: unknown): data is string {
  return typeof data === 'string'
}

export function isNumber(data: unknown): data is number {
  return typeof data === 'number'
}

export function isBoolean(data: unknown): data is boolean {
  return typeof data === 'boolean'
}

export function isNonEmptyString(data: unknown): data is string {
  return isString(data) && data.length > 0
}
