export const inputLocator = (locator: string): string => {
  return `input-${locator}`
}

export const linkLocator = (locator: string): string => {
  return `link-${locator}`
}

export const buttonLocator = (locator: string): string => {
  return `button-${locator}`
}
