import { TutorSubjectModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class TutorProfile extends BaseModel {
  id: string = ''
  project: string = ''
  tutorExperience: boolean = true
  subjects: Array<TutorSubjectModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['subjects'])

      if (Array.isArray(data.subjects)) {
        this.subjects = data.subjects.map(i => new TutorSubjectModel(i))
      }
    }
  }
}
