import type { Role, RoleId } from '~/interfaces/User'

const roleStudent: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/candidate.*/, /^\/digital-profile.*/],
  defaultRoute: '/account/candidate/resumes'
}

const roleUniversity: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/publish.*/],
  defaultRoute: '/account/publish/settings'
}

const roleCompany: Role = {
  allowedRoutes: [
    /^\/account\/?$/,
    /^\/account\/vacancies.*/,
    /^\/account\/resumes-subscriptions.*/,
    /^\/account\/career-routes.*/,
    /^\/account\/event-schedule.*/,
    /^\/account\/executive-search\/?$/
  ],
  defaultRoute: '/account/vacancies'
}

const roleDigitalProfiles: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/university-statistics.*/, /^\/account\/career-routes.*/],
  defaultRoute: '/account/university-statistics'
}

const roleTutor: Role = {
  allowedRoutes: [/^\/account\/?$/, /^\/account\/tutor(\/profile\/(create|edit))?$/],
  defaultRoute: '/account/tutor'
}

const roles: { [key in RoleId]: Role } = {
  student: { ...roleStudent },
  university: { ...roleUniversity },
  company: { ...roleCompany },
  digitalprofiles: { ...roleDigitalProfiles },
  tutor: { ...roleTutor }
}

export default roles
