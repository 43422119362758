import TutorProfileService from './TutorProfileService'
import { ApiService } from '~/shared/api'
import { RequestService } from '~/services/RequestService'

class TutorService extends ApiService {
  protected route: string = 'tutoring'
  public profile: TutorProfileService

  constructor(requestService: RequestService) {
    super(requestService)
    this.profile = new TutorProfileService(requestService)
  }
}

export default TutorService
