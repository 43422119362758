import { emptyRule, requiredRule, maxLengthRule, helpers, betweenNumberRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'
import type { UserModel } from '~/model/User'
import type { DigitalProfile } from '~/model/DigitalProfile'
import { TutorProfileModel } from '~/model/Tutor'
import { ClassifierEntryModel } from '~/model/Classifiers'

type ContactType = 'phone' | 'email' | 'telegram' | 'vkontakte'

export default class TutorProfileForm extends Form {
  university: string = ''
  specialization: string = ''
  studyingStartYear: string = ''
  project: string = ''
  tutorExperience: boolean = true
  subjects: Array<{ subject?: ClassifierEntryModel; score?: number }> = []
  contacts: {
    [key in ContactType]: { value: string; isPreferred: boolean }
  } = {
    phone: {
      value: '',
      isPreferred: false
    },
    email: {
      value: '',
      isPreferred: false
    },
    telegram: {
      value: '',
      isPreferred: false
    },
    vkontakte: {
      value: '',
      isPreferred: false
    }
  }

  constructor(user: UserModel | null, digitalProfile: DigitalProfile, tutorProfile?: TutorProfileModel) {
    super()
    this.fillForm(user, digitalProfile, tutorProfile)
    this.addEmptySubject()
    // this.ignoreKeys = [...this.ignoreKeys, 'university', 'specialization', 'studyingStartYear']
  }

  fillForm(user: UserModel | null, digitalProfile: DigitalProfile, tutorProfile?: TutorProfileModel) {
    this.university = digitalProfile?.university?.name ?? ''
    this.specialization = digitalProfile?.specialization?.name ?? ''
    this.studyingStartYear = digitalProfile?.studying_start_year ? String(digitalProfile.studying_start_year) : ''

    if (user) {
      this.contacts.email.value = user.email
      this.contacts.phone.value = user.phone
    }

    if (tutorProfile) {
      // TODO
    }
  }

  protected toObject(): object {
    const result = {
      project: this.project,
      tutor_experience: Number(this.tutorExperience)
    }

    for (const [key, { value, isPreferred }] of Object.entries(this.contacts)) {
      result[`contacts[${key}][value]`] = value
      result[`contacts[${key}][is_preferred]`] = Number(isPreferred)
    }

    for (const { subject, score } of this.subjects) {
      result[`subjects[${subject!.alias}][id]`] = subject!.id
      result[`subjects[${subject!.alias}][assessment]`] = score
    }

    return result
  }

  protected toFormData(): FormData {
    const result = new FormData()

    result.append('project', this.project)
    result.append('tutor_experience', Number(this.tutorExperience).toString())

    for (const [key, { value, isPreferred }] of Object.entries(this.contacts)) {
      result.append(`contacts[${key}][value]`, value)
      result.append(`contacts[${key}][is_preferred]`, Number(isPreferred).toString())
    }

    for (const { subject, score } of this.subjects) {
      result.append(`subjects[${subject!.alias}][id]`, subject!.id ?? '')
      result.append(`subjects[${subject!.alias}][assessment]`, score?.toString() ?? '')
    }

    return result
  }

  addEmptySubject() {
    this.subjects.push({ subject: undefined, score: undefined })
  }

  deleteSubject(idx: number) {
    this.subjects.splice(idx, 1)
  }

  static rules() {
    return {
      university: requiredRule,
      specialization: requiredRule,
      studyingStartYear: requiredRule,
      project: maxLengthRule(200),
      tutorExperience: emptyRule,
      contacts: emptyRule,
      subjects: {
        $each: helpers.forEach({
          subject: requiredRule,
          score: { ...requiredRule, ...betweenNumberRule(1, 100) }
        })
      }
    }
  }
}
