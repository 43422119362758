
import { computed, defineComponent, PropType } from 'vue-demi'
import { SvoiTooltippedIcon } from '@svoi-ui/components/icon'
import type { Type } from '@svoi-ui/interfaces/button'
import { isString } from '@svoi-ui/shared/utils/guards'
import { buttonLocator } from '@svoi-ui/shared/utils/helpers'
import { BaseButtonComponent, useBaseButton } from './BaseButton'

export default defineComponent({
  name: 'SvoiButton',
  components: { SvoiTooltippedIcon },
  props: {
    ...BaseButtonComponent.props,
    type: {
      type: String as PropType<Type | string>,
      default: 'button',
      validator: (value: unknown) => {
        return isString(value) && ['submit', 'button', 'reset'].includes(value)
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    loaderIconLeft: {
      type: Boolean,
      default: true
    },
    loaderIconRight: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'mouseover', 'mouseleave', 'focus'],
  setup(props) {
    const { classes, iconSize } = useBaseButton(props)

    const classLoading = computed(() => {
      return { '-loading -theme-loading': props.loading }
    })
    const qaLocator = computed(() => (props.qaLocator ? buttonLocator(props.qaLocator) : undefined))

    return { classes, iconSize, classLoading, qaLocator }
  },
  methods: { isString }
})
