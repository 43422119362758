import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { ClassifierEntryModel } from '~/model/Classifiers'

export default class TutorSubject extends BaseModel {
  id: string = ''
  status: string = ''
  entry: ClassifierEntryModel = new ClassifierEntryModel()

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['entry'])

      if (isUnknownObject(data.entry)) {
        this.entry = new ClassifierEntryModel(data.entry)
      }
    }
  }
}
