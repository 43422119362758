import { Context, Middleware } from '@nuxt/types'
import { AxiosError } from 'axios'
import type { CustomAxiosRequestConfig } from '~/shared/interfaces'

/**
 * Попытка авторизации пользователя
 */
const authorize: Middleware = async ({ route, $services, $tstore, $axios }: Context) => {
  if (route.query.token) {
    $services.user.setCookieToken(route.query.token)
  }
  if (route.query.refresh_token) {
    $services.user.setCookieRefreshToken(route.query.refresh_token)
  }
  if (route.query.update_refresh_token) {
    $services.user.updateRefreshToken(route.query.update_refresh_token)
  }

  $axios.interceptors.response.use(
    response => {
      return response
    },
    async (error: Error | AxiosError) => {
      console.log('ERROR', error) // eslint-disable-line no-console
      if (typeof $axios.isAxiosError === 'function' && $axios.isAxiosError(error)) {
        console.log('isAxiosError', error.response?.status) // eslint-disable-line no-console
        const originalRequest: CustomAxiosRequestConfig = error.config

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          console.log('ERROR 401', error.response?.status) // eslint-disable-line no-console
          const tokenResponse = await $services.user.getAccessTokenByRefresh()
          if (tokenResponse) {
            $services.user.setCookieToken(tokenResponse.accessToken)
            $services.user.setCookieRefreshToken(tokenResponse.refreshToken)
            $axios.defaults.headers.common['Authorization-SF-Token'] = tokenResponse.accessToken
          }

          return $axios(originalRequest)
        }
      }
      return Promise.reject(error)
    }
  )

  const token = $services.user.getCookieToken()
  const tokenChanged = !!route.query.token
  const needAuth = !$tstore.auth.isLogged && token

  if (needAuth || tokenChanged) {
    const user = await $services.user.getAuthorizedUser()
    if (user !== null) {
      $tstore.auth.setUser(user)
      $axios.defaults.headers.common['Authorization-SF-Token'] = token
    } else {
      $services.user.removeToken()
    }
  }
}
export default authorize
