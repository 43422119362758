import { Vue } from 'nuxt-property-decorator'
import type { MenuItem } from '~/interfaces/User'

/**
 * Инициализация меню проходит в компоненте через store ($tstore.account.initMenu)
 * Необходим рефакторинг инициализации меню после отвязки проекта от ui-kit
 */
export default class Menu {
  private readonly context: Vue
  private readonly primaryItems: Array<MenuItem> = [
    {
      icon: 'file',
      text: 'Мое резюме',
      onClick: () => this.goToPage('/account/candidate/resumes'),
      roles: ['student']
    },
    {
      icon: 'accept-folder',
      text: 'Отклики',
      onClick: () => this.goToPage('/account/candidate/feedbacks'),
      roles: ['student']
    },
    {
      icon: 'file',
      text: 'Мои вакансии',
      onClick: () => this.goToPage('/account/vacancies'),
      roles: ['company']
    },
    {
      icon: 'accept-folder',
      text: 'Отклики',
      onClick: () => this.goToPage('/account/vacancies/feedbacks'),
      roles: ['company']
    },
    {
      icon: 'calendar',
      text: 'Афиша мероприятий',
      onClick: () => this.goToPage('/account/event-schedule'),
      roles: ['company']
    },
    {
      icon: 'search',
      text: 'Подписки на резюме',
      onClick: () => this.goToPage('/account/resumes-subscriptions'),
      roles: ['company']
    },
    {
      icon: 'people',
      text: 'Цифровые профили',
      onClick: () => this.goToPage('/account/university-statistics'),
      roles: ['digitalprofiles']
    },
    {
      icon: 'news',
      text: 'Новости',
      onClick: () => this.goToPage('/account/publish/news'),
      roles: ['university']
    },
    {
      icon: 'briefcase3',
      text: 'Карьерные маршруты',
      onClick: () => this.goToPage('/account/career-routes'),
      roles: ['company', 'digitalprofiles']
    },
    {
      id: 'tutor',
      icon: 'book',
      text: 'Репетиторство',
      onClick: () => this.goToPage('/account/tutor'),
      roles: ['tutor']
    }
  ]

  private readonly secondaryItems: Array<MenuItem> = [
    {
      icon: 'settings',
      text: 'Настройки публикаций',
      onClick: () => this.goToPage('/account/publish/settings'),
      roles: ['university']
    },
    {
      icon: 'settings',
      text: 'Настройки аккаунта',
      onClick: () => this.onClickAccountSettings(),
      roles: ['student', 'university', 'company', 'digitalprofiles', 'tutor']
    },
    {
      icon: 'exit',
      text: 'Выйти',
      onClick: () => this.onClickExit(),
      roles: ['student', 'university', 'company', 'digitalprofiles', 'tutor']
    }
  ]

  constructor(context: Vue) {
    this.context = context
  }

  getSecondaryItems(): Array<MenuItem> {
    return this.secondaryItems.filter(item => this.checkAccess(item))
  }

  getPrimaryItems(): Array<MenuItem> {
    return this.primaryItems.filter(item => this.checkAccess(item))
  }

  private checkAccess(item: MenuItem): boolean {
    if (item.roles === undefined) {
      return true
    }

    const authorizedUser = this.context.$tstore.auth.authorizedUser
    if (authorizedUser === null) {
      return false
    }

    return item.roles.filter(r => authorizedUser.roles.has(r)).length > 0
  }

  private goToPage(url: string): void {
    this.context.$router.push(url)
  }

  private onClickAccountSettings(): void {
    window.location.href = `${this.context.$config.hosts.authHost}/my-account/edit`
  }

  private onClickExit(): void {
    const authHostLogoutUrl = this.context.$config.hosts.authHostLogoutUrl
    const externalHost = this.context.$config.hosts.externalHost

    this.context.$services.user.removeToken()
    window.location.href = `${authHostLogoutUrl}?backurl=${externalHost}/`
  }
}
