const middleware = {}

middleware['authorize'] = require('../middleware/authorize.ts')
middleware['authorize'] = middleware['authorize'].default || middleware['authorize']

middleware['backPage'] = require('../middleware/backPage.ts')
middleware['backPage'] = middleware['backPage'].default || middleware['backPage']

middleware['check-access'] = require('../middleware/check-access.ts')
middleware['check-access'] = middleware['check-access'].default || middleware['check-access']

middleware['geolocation'] = require('../middleware/geolocation.ts')
middleware['geolocation'] = middleware['geolocation'].default || middleware['geolocation']

middleware['geolocationUser'] = require('../middleware/geolocationUser.ts')
middleware['geolocationUser'] = middleware['geolocationUser'].default || middleware['geolocationUser']

middleware['isDigitalProfile'] = require('../middleware/isDigitalProfile.ts')
middleware['isDigitalProfile'] = middleware['isDigitalProfile'].default || middleware['isDigitalProfile']

middleware['page-metadata'] = require('../middleware/page-metadata.ts')
middleware['page-metadata'] = middleware['page-metadata'].default || middleware['page-metadata']

middleware['redirect-to-default'] = require('../middleware/redirect-to-default.ts')
middleware['redirect-to-default'] = middleware['redirect-to-default'].default || middleware['redirect-to-default']

middleware['redirect-to'] = require('../middleware/redirect-to.ts')
middleware['redirect-to'] = middleware['redirect-to'].default || middleware['redirect-to']

middleware['redirectToChPU'] = require('../middleware/redirectToChPU.ts')
middleware['redirectToChPU'] = middleware['redirectToChPU'].default || middleware['redirectToChPU']

middleware['vacanciesDuplicationUrl'] = require('../middleware/vacanciesDuplicationUrl.ts')
middleware['vacanciesDuplicationUrl'] = middleware['vacanciesDuplicationUrl'].default || middleware['vacanciesDuplicationUrl']

export default middleware
