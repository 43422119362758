import { ApiService } from '~/shared/api'
import { restGetList } from '~/shared/utils/decorators'
import type { ServiceRestGetList, UnknownObject } from '~/shared/interfaces'
import { ClassifierEntryModel } from '~/model/Classifiers'
import type { ClassifiersListParams } from '~/interfaces/Classifiers'
import { toModels } from '~/shared/api/utils'

// eslint-disable-next-line
interface ClassifiersService extends ServiceRestGetList {}

@restGetList
// eslint-disable-next-line no-redeclare
class ClassifiersService extends ApiService {
  protected route: string = 'classifiers/entries/query'

  getListModel(params: ClassifiersListParams = {}): Promise<Array<ClassifierEntryModel>> {
    return this.getList(params as UnknownObject).then(toModels(ClassifierEntryModel))
  }
}

export default ClassifiersService
