import { email, helpers, minLength, required, maxLength, between } from '@vuelidate/validators'
import { validationMessages } from '~/shared/const/validation'
import { replaceParams } from '~/shared/utils/helpers'

export { helpers } from '@vuelidate/validators'

export const requiredRule = {
  required: helpers.withMessage(validationMessages.required, required)
}

export const onlyCyrillicRule = {
  onlyCyrillic: helpers.withMessage(validationMessages.onlyCyrillic, helpers.regex(/^[а-яА-ЯёЁ\s]+$/))
}

export const emailRule = {
  email: helpers.withMessage(validationMessages.email, email)
}

export const phoneRule = {
  phone: helpers.withMessage(validationMessages.phone, helpers.regex(/^\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/))
}

export const checkedRule = {
  required: helpers.withMessage(validationMessages.required, (value: unknown) => value === true)
}

export const emptyRule = {
  emptyRule: helpers.withMessage('', () => true)
}

export function minLengthRule(min: number) {
  return {
    minLength: helpers.withMessage(replaceParams(validationMessages.minLength, { min }), minLength(min))
  }
}

export function minLengthCountRule(min: number) {
  return {
    minLengthString: helpers.withMessage(
      ({ $model }) => replaceParams(validationMessages.minLengthCount, { min, length: String($model).length }),
      minLength(min)
    )
  }
}

export function maxLengthRule(max: number) {
  return {
    maxLength: helpers.withMessage(replaceParams(validationMessages.maxLength, { max }), maxLength(max))
  }
}

export function betweenNumberRule(min: number, max: number) {
  return {
    betweenNumber: helpers.withMessage(replaceParams(validationMessages.betweenNumber, { min, max }), between(min, max))
  }
}
