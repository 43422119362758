import { isUnknownObject } from '~/shared/utils/guards'
import { BaseModel } from '~/shared/model'
import { messages } from '~/shared/const/messages'
import type { UnknownObject } from '~/shared/interfaces'

export function unwrapKeyResponse(unwrapData: unknown, key: unknown): unknown {
  if (isUnknownObject(unwrapData)) {
    const raw = isUnknownObject(unwrapData.data) ? unwrapData.data : unwrapData

    if (raw[`${key}`]) {
      return raw[`${key}`]
    }
  }

  return null
}

export function toKeyModel<T extends BaseModel>(
  constructor: new (...args: any[]) => T,
  errorMessage?: string,
  key?: string
) {
  return (data: unknown) => {
    const resp = unwrapKeyResponse(data, key)

    if (resp && isUnknownObject(resp)) {
      return new constructor(resp)
    }

    throw new Error(errorMessage ?? messages.errors.request)
  }
}

export function unwrapResponse(unwrapData: unknown): unknown {
  if (isUnknownObject(unwrapData)) {
    const raw: unknown | UnknownObject = unwrapData.data ?? unwrapData

    if (isUnknownObject(raw)) {
      const firstKey = Object.keys(raw).shift()
      if (firstKey !== undefined) {
        return raw[firstKey]
      }
    }

    return raw
  }

  return unwrapData
}

export function toModel<T extends BaseModel>(constructor: new (...args: any[]) => T, errorMessage?: string) {
  return (data: unknown) => {
    const resp = unwrapResponse(data)

    if (resp && isUnknownObject(resp)) {
      return new constructor(resp)
    }

    throw new Error(errorMessage ?? messages.errors.request)
  }
}

export function toModels<T extends BaseModel>(constructor: new (...args: any[]) => T) {
  return (data: unknown): Array<T> => {
    const resp = unwrapResponse(data)

    if (Array.isArray(resp)) {
      return resp.map((i: unknown) => new constructor(i))
    }

    return []
  }
}
