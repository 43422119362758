import { ApiService } from '~/shared/api'
import { restGetItem } from '~/shared/utils/decorators'
import { ServiceRestGetItem } from '~/shared/interfaces'
import { CareerRoutesDetailModel } from '~/model/CareerRoutes'
import { isUnknownObject } from '~/shared/utils/guards'

// eslint-disable-next-line
interface CareerRouteService extends ServiceRestGetItem {}

// @ts-ignore
@restGetItem

// eslint-disable-next-line no-redeclare
class CareerRouteService extends ApiService {
  protected route: string = 'career-routes'

  getItemModel(slug: string): Promise<CareerRoutesDetailModel> {
    return this.getItem(slug).then(({ data }) => {
      const raw = isUnknownObject(data) && isUnknownObject(data.data) ? data.data : null
      return new CareerRoutesDetailModel(raw)
    })
  }
}

export default CareerRouteService
