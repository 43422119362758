export const validationMessages = {
  required: 'Поле обязательно для заполнения',
  onlyCyrillic: 'Содержатся некорректные символы',
  email: 'Некорректный email',
  phone: 'Неверный формат номера телефона',
  minLength: 'Длина не должна быть меньше ${min}', // eslint-disable-line no-template-curly-in-string
  maxLength: 'Длина не должна быть больше ${max}', // eslint-disable-line no-template-curly-in-string
  minLengthCount: `$\{length}/15 000 символов. Минимальное количество символов — $\{min}`,
  betweenNumber: 'Значение должно быть не меньше ${min} и не больше ${max}' // eslint-disable-line no-template-curly-in-string
}
