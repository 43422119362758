
import { defineComponent, ref, useContext, onMounted, watch } from '@nuxtjs/composition-api'
import TopMenu from '~/components/Header/TopMenu.vue'
import BottomMenu from '~/components/Header/BottomMenu.vue'
import MobileSidebarMenu from '~/components/Header/MobileSidebarMenu.vue'
import TopDigitalProfileBanner from '~/components/Banners/TopDigitalProfileBanner.vue'
import GlobalSearch from '~/components/Misc/GlobalSearch.vue'
import { getMobileSidebarMenu } from '~/shared/const'
import { isUnknownObject } from '~/shared/utils/guards'
import type { MenuItem } from '~/interfaces/Header'

export default defineComponent({
  name: 'Header',
  components: {
    TopDigitalProfileBanner,
    MobileSidebarMenu,
    TopMenu,
    BottomMenu,
    GlobalSearch
  },
  props: {
    isShowTopBanner: {
      type: Boolean,
      default: false
    }
  },
  emits: ['on-search', 'on-search-input'],
  setup() {
    const context = useContext()

    const showMobileSidebar = ref(false)
    const sidebarMenu = ref<Array<MenuItem>>([])

    const getSidebarMenu = () => {
      const region = context.$services.geolocation.getCookieRegion()
      sidebarMenu.value = getMobileSidebarMenu(
        isUnknownObject(region) && typeof region.slug === 'string' ? region.slug : ''
      )
    }

    onMounted(() => getSidebarMenu())

    watch(() => context.$tstore.regionLocation.RegionLocationItem, getSidebarMenu, { immediate: true, deep: true })

    return {
      sidebarMenu,
      showMobileSidebar
    }
  }
})
