import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class ClassifierEntry extends BaseModel {
  id: string = ''
  alias: string = ''
  value: string = ''

  constructor(data?: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
