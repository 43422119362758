import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class CareerRoutesDetailEmployerModel extends BaseModel {
  name: string = ''
  url: string = ''
  logo: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.logo) {
        this.logo = new ImageService().cropImage(data.logo, {})
      }
    }
  }
}
