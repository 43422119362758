import { TutorProfileForm } from '.'
import { ApiService } from '~/shared/api'
import { restGetList, restCreate, sendForm, restDelete } from '~/shared/utils/decorators'
import type {
  ServiceRestGetList,
  ServiceRestCreate,
  ServiceCanSendForm,
  QueryParams,
  ServiceRestDelete
} from '~/shared/interfaces'
import { TutorProfileModel } from '~/model/Tutor'
import { toModel, toModels } from '~/shared/api/utils'

// eslint-disable-next-line
interface TutorProfileService extends ServiceRestGetList, ServiceRestCreate, ServiceCanSendForm, ServiceRestDelete {}

@restGetList
@sendForm
@restCreate
@restDelete
// eslint-disable-next-line no-redeclare
class TutorProfileService extends ApiService {
  protected route: string = 'tutoring/requests'

  getListModel(params: QueryParams = {}): Promise<Array<TutorProfileModel>> {
    return this.getList(params).then(toModels(TutorProfileModel))
  }

  createModel(form: TutorProfileForm): Promise<TutorProfileModel> {
    return this.create(form.setSendAs('form-data')).then(toModel(TutorProfileModel))
  }
}

export default TutorProfileService
