import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import { ServiceHasList } from '~/shared/interfaces'
import { RequestService } from '~/services/RequestService'
import {
  CareerRouteService,
  CareerRoutesCategoryService,
  CareerRoutesAccountService
} from '~/services/CareerRoutes/index'

// eslint-disable-next-line
interface CareerRoutesService extends ServiceHasList {}

// @ts-ignore
@getList

// eslint-disable-next-line no-redeclare
class CareerRoutesService extends ApiService {
  protected route: string = 'career-routes/list'

  careerRouteService: CareerRouteService
  categoriesService: CareerRoutesCategoryService
  accountService: CareerRoutesAccountService

  constructor(requestService: RequestService) {
    super(requestService)
    this.careerRouteService = new CareerRouteService(requestService)
    this.categoriesService = new CareerRoutesCategoryService(requestService)
    this.accountService = new CareerRoutesAccountService(requestService)
  }
}

export default CareerRoutesService
