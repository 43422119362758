import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { DigitalProfile, INdaFile, ListInDigitalProfile, Specialization } from '~/model/DigitalProfile'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'digitalProfile'
})
export class DigitalProfileModule extends VuexModule {
  private profile: DigitalProfile = new DigitalProfile()
  private dictionaryCourses: ListInDigitalProfile[] = []
  private dictionarySpecializations: Specialization[] = []
  private dictionaryFinance: ListInDigitalProfile[] = []
  private dictionaryEducationLevel: ListInDigitalProfile[] = []
  private dictionaryStudyingForms: ListInDigitalProfile[] = []
  private dictionaryCitiesByRegion: ListInDigitalProfile[] = []
  private dictionaryCitizenship: ListInDigitalProfile[] = []
  private isDigitalProfile: boolean = false
  private ndaFile: INdaFile = new INdaFile()

  @Mutation
  private mutationSetNdaFile(ndaFile: INdaFile): void {
    this.ndaFile = ndaFile
  }

  @Mutation
  private mutationSetDigitalProfile(profile: DigitalProfile): void {
    this.profile = profile
  }

  @Mutation
  private mutationSetIsDigitalProfile(isDigitalProfile: boolean): void {
    this.isDigitalProfile = isDigitalProfile
  }

  @Mutation
  private mutationSetDictionaryFinance(finance: ListInDigitalProfile[]): void {
    this.dictionaryFinance = finance
  }

  @Mutation
  private mutationSetDictionaryCourse(courses: ListInDigitalProfile[]): void {
    this.dictionaryCourses = courses
  }

  @Mutation
  private mutationSetDictionarySpecialization(specializations: Specialization[]): void {
    this.dictionarySpecializations = specializations
  }

  @Mutation
  private mutationSetDictionaryEducationLevel(educationLevel: ListInDigitalProfile[]): void {
    this.dictionaryEducationLevel = educationLevel
  }

  @Mutation
  private mutationSetDictionaryStudyingForms(studyingForms: ListInDigitalProfile[]): void {
    this.dictionaryStudyingForms = studyingForms
  }

  @Mutation
  private mutationSetDictionaryCitiesByRegion(cities: ListInDigitalProfile[]): void {
    this.dictionaryCitiesByRegion = cities
  }

  @Mutation
  private mutationSetDictionaryCitizenship(citizenship: ListInDigitalProfile[]): void {
    this.dictionaryCitizenship = citizenship
  }

  @Action
  public setNdaFile(ndaFile: INdaFile): void {
    this.context.commit('mutationSetNdaFile', ndaFile)
  }

  @Action
  public setDictionaryCourse(courses: ListInDigitalProfile[]): void {
    this.context.commit('mutationSetDictionaryCourse', courses)
  }

  @Action
  public setDictionarySpecializations(specializations: Specialization[]): void {
    this.context.commit('mutationSetDictionarySpecialization', specializations)
  }

  @Action
  public setDictionaryFinance(finance: ListInDigitalProfile[]): void {
    this.context.commit('mutationSetDictionaryFinance', finance)
  }

  @Action
  public setDictionaryEducationLevel(educationLevel: ListInDigitalProfile[]): void {
    this.context.commit('mutationSetDictionaryEducationLevel', educationLevel)
  }

  @Action
  public setDictionaryStudyingForms(studyingForms: ListInDigitalProfile[]): void {
    this.context.commit('mutationSetDictionaryStudyingForms', studyingForms)
  }

  @Action
  public setDictionaryCitiesByRegion(cities: ListInDigitalProfile[]): void {
    this.context.commit('mutationSetDictionaryCitiesByRegion', cities)
  }

  @Action
  public setDictionaryCitizenship(citizenship: ListInDigitalProfile[]): void {
    this.context.commit('mutationSetDictionaryCitizenship', citizenship)
  }

  @Action
  public setDigitalProfile(profile: DigitalProfile): void {
    this.context.commit('mutationSetDigitalProfile', profile)
    this.context.commit('mutationSetIsDigitalProfile', true)
  }

  @Action
  public setIsDigitalProfile(isDigitalProfile: boolean): void {
    this.context.commit('mutationSetIsDigitalProfile', isDigitalProfile)
  }

  get NdaFile(): INdaFile {
    return this.ndaFile
  }

  get DigitalProfile(): DigitalProfile {
    return this.profile
  }

  get DictionaryCourse(): ListInDigitalProfile[] {
    return this.dictionaryCourses
  }

  get DictionarySpecialization(): Specialization[] {
    return this.dictionarySpecializations
  }

  get DictionaryFinance(): ListInDigitalProfile[] {
    return this.dictionaryFinance
  }

  get DictionaryEducationLevel(): ListInDigitalProfile[] {
    return this.dictionaryEducationLevel
  }

  get DictionaryStudyingForms(): ListInDigitalProfile[] {
    return this.dictionaryStudyingForms
  }

  get DictionaryCitiesByRegion(): ListInDigitalProfile[] {
    return this.dictionaryCitiesByRegion
  }

  get DictionaryCitizenship(): ListInDigitalProfile[] {
    return this.dictionaryCitizenship
  }

  get IsDigitalProfile(): boolean {
    return this.isDigitalProfile
  }
}
