import { UI_KIT_STORE_PREFIX } from '@rshb/rshb-vue-kit/src/const'
import { SocialService } from '.'
import {
  COOKIE_TOKEN_KEY,
  COOKIE_PATH,
  COOKIE_MAX_AGE_SECONDS,
  COOKIE_UNIQUE_TOKEN,
  COOKIE_UNIQUE_TOKEN_KEY,
  COOKIE_REFRESH_TOKEN_KEY,
  COOKIE_UPDATE_REFRESH_TOKEN_KEY
} from '~/shared/const'
import { UserModel } from '~/model/User'
import { ApiService } from '~/shared/api'
import type { Nullable } from '~/shared/interfaces'
import { RequestService } from '~/services/RequestService'
import { isUnknownObject } from '~/shared/utils/guards'
import { RefreshTokenModel } from '~/model/Response'

export default class Service extends ApiService {
  private token: string | null = null
  protected route = 'users-management'
  public socialService: SocialService

  constructor(
    requestService: RequestService,
    private cookies: any,
    private currentRoute: any /** @deprecated удалить после замены методов getUser и getResume */,
    private store: any,
    private config: any
  ) {
    super(requestService)
    this.socialService = new SocialService(requestService)
  }

  public getAuthorizedUser(): Promise<Nullable<UserModel>> {
    this.getUserForUiKit()

    return this.requestService
      .request({
        url: `${this.prefix}/${this.route}/users/sf/authorize`,
        method: 'POST',
        headers: {
          'Authorization-SF-Token': this.getCookieToken()
        }
      })
      .then(({ data }) => {
        console.log('authorize success') // eslint-disable-line no-console
        if (isUnknownObject(data) && isUnknownObject(data.data)) {
          return new UserModel(data.data)
        }
        return null
      })
      .catch(error => {
        console.log('authorize error', error) // eslint-disable-line no-console
        return null
      })
  }

  /**
   * @deprecated
   * Удалить метод после рефакторинга. Некоторые страницы используют данные пользователя из @rshb/ui-kit/store/auth
   */
  public getUserForUiKit(): void {
    const token = this.getCookieToken()
    const apiHost = this.config.hosts.authHost

    this.store.dispatch(`${UI_KIT_STORE_PREFIX}/auth/getUser`, { token, apiHost })
  }

  /**
   * @deprecated
   * Необходимо использовать типизированную версию getAuthorizedUser
   * или взять текущего авторизованного пользователя из $tstore.auth.authorizedUser
   */
  public async getUser(): Promise<any> {
    try {
      const token = this.currentRoute.query.token || this.cookies.get(COOKIE_TOKEN_KEY)

      await this.store.dispatch(`${UI_KIT_STORE_PREFIX}/auth/getUser`, {
        token,
        apiHost: this.config.hosts.authHost
      })

      return await this.store.getters[`${UI_KIT_STORE_PREFIX}/auth/authUser`]
    } catch (e) {
      console.warn(e) // eslint-disable-line no-console
    }
  }

  /**
   * @deprecated
   * перенести метод в другой модуль
   */
  public async getResume(): Promise<any> {
    try {
      const token = this.currentRoute.query.token || this.cookies.get(COOKIE_TOKEN_KEY)

      await this.store.dispatch(`${UI_KIT_STORE_PREFIX}/recruitment/user/getUserResume`, {
        token,
        apiHost: this.config.hosts.externalHost
      })

      return await this.store.getters[`${UI_KIT_STORE_PREFIX}/recruitment/user/authUserResume`]
    } catch (e) {
      console.warn(e) // eslint-disable-line no-console
    }
  }

  public getAccessTokenByRefresh(): Promise<Nullable<RefreshTokenModel>> {
    const updateRefreshToken: string = this.getUpdateRefreshCookieToken()
    if (updateRefreshToken) {
      this.updateRefreshToken(updateRefreshToken)
    }
    const refreshToken: string = this.getRefreshCookieToken()
    if (!refreshToken) {
      this.logout()
    }

    return this.requestService
      .request({
        url: `${this.config.hosts.authHost}/api/ext/rshb-auth/refresh`,
        method: 'POST',
        data: {
          refreshToken
        }
      })
      .then(({ data }) => {
        console.log('refresh success') // eslint-disable-line no-console
        if (isUnknownObject(data) && isUnknownObject(data.result) && data.result.access_token) {
          return new RefreshTokenModel(data.result)
        }
        return null
      })
      .catch(error => {
        console.log('refresh error', error) // eslint-disable-line no-console
        return null
      })
  }

  public getCookieToken(): string {
    return this.cookies.get(COOKIE_TOKEN_KEY) || this.token
  }

  public getRefreshCookieToken(): string {
    return this.cookies.get(COOKIE_REFRESH_TOKEN_KEY) || ''
  }

  public getUpdateRefreshCookieToken(): string {
    return this.cookies.get(COOKIE_UPDATE_REFRESH_TOKEN_KEY) || ''
  }

  public setCookieToken(token): void {
    this.token = token
    this.cookies.set('token', token, {
      path: COOKIE_PATH,
      maxAge: COOKIE_MAX_AGE_SECONDS
    })
  }

  public getCookieUniquePersonToken(): string {
    return this.cookies.get(COOKIE_UNIQUE_TOKEN_KEY)
  }

  public setCookieUniquePersonToken(): void {
    this.cookies.set(COOKIE_UNIQUE_TOKEN_KEY, COOKIE_UNIQUE_TOKEN)
  }

  public setCookieRefreshToken(token): void {
    this.cookies.set('refresh_token', token, {
      path: COOKIE_PATH,
      maxAge: COOKIE_MAX_AGE_SECONDS
    })
  }

  public updateRefreshToken(token): void {
    this.setCookieRefreshToken(token)
    this.cookies.remove(COOKIE_UPDATE_REFRESH_TOKEN_KEY)
  }

  public removeToken(): void {
    this.cookies.remove(COOKIE_TOKEN_KEY)
  }

  public logout(): void {
    const authHostLogoutUrl = this.config.hosts.authHostLogoutUrl
    const externalHost = this.config.hosts.externalHost

    this.removeToken()
    location.href = `${authHostLogoutUrl}?backurl=${externalHost}/`
  }
}
